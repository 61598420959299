import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../context/NaviContext'
import { Transition } from 'react-transition-group'
import anime from 'animejs'

import Hamburger from './Hamburger'
import Navi from './Navi'
import SocialLinks from '../SocialLinks'

function Header(props) {
	const naviContext = useContext(NaviContext)

	// Animating fade in/out
	const baseDuration = 500
	const fadeInLogo = element => {
		const links = element.querySelectorAll('.nav-item.parent, .social-icons a')
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				duration: baseDuration,
				easing: 'easeInOutSine',
			})
			.add({
				targets: links,
				opacity: [0, 1],
				translateX: [-32, 0],
				duration: baseDuration / 2,
				easing: 'easeInOutSine',
				delay: anime.stagger(75)
			}, `-=${baseDuration / 3}`)
	}
	const fadeOutLogo = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				duration: baseDuration / 2,
				easing: 'linear'
			})
	}

  return (
		<header id='master-header' className={'c5 border-type-1 skip-animation'}>

			<div className="decor-wrap">
				<div className="decor-top">
					<div className="decor-top-left"></div>
					<div className="decor-top-center"></div>
					<div className="decor-top-right"></div>
				</div>
				<div className="decor-center">
					<div className="decor-center-left"></div>
					<div className="decor-center-right"></div>
				</div>
				<div className="decor-bottom">
					<div className="decor-bottom-left"></div>
					<div className="decor-bottom-center"></div>
					<div className="decor-bottom-right"></div>
				</div>
			</div>
			
			<Hamburger {...props}/>									

			{/* {naviContext.beforeHeaderBreakpoint ? 'true' :	
			naviContext.scrollingDirectionIsDown ? 'false' : 'true'} */}
			<Transition
				in={naviContext.isActive ? true :	false}
				timeout={baseDuration}
				appear={true}
				onEntering={fadeInLogo}
				onExiting={fadeOutLogo}
				mountOnEnter
				unmountOnExit
			>
				<div className={`navi-animator c5 ${naviContext.isHamburgerActive && !naviContext.beforeHeaderBreakpoint ? null : 't'}`}>
		
					<Navi {...props} />
					<SocialLinks />
					<div className="close-hamburger" role='button' tabIndex={0} aria-label='Close navigation' onClick={() => { naviContext.activeToggle(false); naviContext.hamburgerActiveToggle(false)}}>
            <div className="hamburger-container hamburger hamburger--close1 open x0 t">
              <div className="hamburger__icon">
                <div className="hamburger__line hamburger__line--1"></div>
                <div className="hamburger__line hamburger__line--2"></div>
                <div className="hamburger__line hamburger__line--3"></div>
              </div>
            </div>
          </div>
          
				</div>
			</Transition>
		</header>
  )
}

export default Header